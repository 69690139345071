export const CheckBoxIcon = () => {
  return (
    <svg
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.86988 6.87545C4.13398 7.00796 4.45708 7.06355 4.80247 6.77377L10.928 1.26758C11.5208 0.591361 12.2504 1.36408 11.9156 1.75052L5.29683 10.6378C4.90165 11.1207 4.2101 11.1208 3.81493 10.6378L0.0986096 5.83662C-0.236132 5.42137 0.356771 4.93837 0.752173 5.22817L3.86988 6.87545Z'
        fill='currentColor'
      />
    </svg>
  );
};
