export const getRouteMain = () => '/';
export const getRouteAuth = () => '/auth';
export const getRouteLogin = () => '/login';
export const getRouteCart = () => '/cart';
export const getRouteMarket = () => '/shops/:id';
export const getRouteMyAccount = () => '/myaccount';
export const getRouteOrderHistory = () => '/orders-history';
export const getRouteCatalog = () => '/shops/:id/catalog/:shopId/category?/:catId?';
export const getRouteCheckout = () => '/checkout';
export const getRouteOrderConfirmed = () => '/order/:orderId/confirm';
export const getRouteChat = () => 'chat/:chatId';
export const getRouteWholesaleBuyers = () => '/wholesale-buyers';
export const getRouteSearch = () => '/search-results';
export const getRouteUserAgreement = () => '/user-agreement';
export const getRoutePolicy = () => '/policy';
export const getRouteNotFound = () => '*';
