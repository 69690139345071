import { useContext } from 'react';

import { LOCAL_STORAGE_THEME_KEY, Theme, ThemeContext } from './ThemeContext';

interface UseThemeResult {
  toggleTheme: () => void;
  isTheme: Theme;
}

export function useTheme(): UseThemeResult {
  const { isTheme = Theme.MAIN, setIsTheme } = useContext(ThemeContext);

  const toggleTheme = () => {
    const newTheme = isTheme === Theme.SECONDARY ? Theme.SECONDARY : Theme.MAIN;
    setIsTheme?.(newTheme);
    localStorage.setItem(LOCAL_STORAGE_THEME_KEY, newTheme);
  };

  return {
    isTheme,
    toggleTheme
  };
}
