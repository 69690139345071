import { useState, useLayoutEffect } from 'react';
import { IMatchedMedia, IMediaQuery } from '../../types/MatchMediaTyped';

const useMatchMedia = (queries: IMediaQuery, defaultValues: IMatchedMedia = []): IMatchedMedia => {
  const initialValues = defaultValues.length ? defaultValues : Array(queries.length).fill(false);

  const mediaQueryLists = queries.map((q) => window.matchMedia(q));
  const getValue = (): IMatchedMedia => {
    // Return the value for the given queries
    const matchedQueries = mediaQueryLists.map((mql) => mql.matches);

    return matchedQueries;
  };

  const [value, setValue] = useState(getValue);
  // TODO исправить тип
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useLayoutEffect(() => {
    const handler = (): void => setValue(getValue);
    mediaQueryLists.forEach((mql) => mql.addListener(handler));
    return (): void => mediaQueryLists.forEach((mql) => mql.removeListener(handler));
  }, []);

  return value;
};

export default useMatchMedia;
