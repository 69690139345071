export function ClockIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='17'
      height='17'
      viewBox='0 0 17 17'
      fill='none'
    >
      <mask
        id='mask0_38_528'
        // style='mask-type:luminance'
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='17'
        height='17'
      >
        <path
          d='M8.5 16C12.6423 16 16 12.6423 16 8.5C16 4.35775 12.6423 1 8.5 1C4.35775 1 1 4.35775 1 8.5C1 12.6423 4.35775 16 8.5 16Z'
          fill='white'
          stroke='white'
          strokeWidth='1.5'
          strokeLinejoin='round'
        />
        <path
          d='M8.50293 4V8.50375L11.6826 11.6838'
          stroke='black'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </mask>
      <g mask='url(#mask0_38_528)'>
        <path
          d='M-0.5 -0.5H17.5V17.5H-0.5V-0.5Z'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
