export function PlusIcon() {
  return (
    <svg
      width='11'
      height='11'
      viewBox='0 0 11 11'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.3381 4.6619H11V6.33809H6.3381V11H4.6619V6.33809H0V4.6619H4.6619V0H6.3381V4.6619Z'
        fill='currentColor'
      />
    </svg>
  );
}
