import { makeAutoObservable } from 'mobx';
import { RootStore } from '.';
import { INIT, STATE } from '../shared/const/state.const';

class PageStore {
  pageName: string;
  isOpen: boolean;
  state: STATE;
  showOrder: (number | null)[];
  constructor(rootStore: RootStore) {
    this.state = INIT;
    this.pageName = '';
    this.isOpen = false;
    this.showOrder = [];
    makeAutoObservable(this);
  }

  setPageName = (pageName: string) => {
    this.pageName = pageName;
  };
  setIsOpen = (isOpen: boolean) => {
    this.isOpen = isOpen;
  };
  setShowOrder = (showOrder: number) => {
    if (this.showOrder.find((order) => order === showOrder)) {
      this.showOrder = this.showOrder.filter((order) => order !== showOrder);
    } else {
      this.showOrder = [...this.showOrder, showOrder];
    }
  };
  clearShowOrder = () => {
    this.showOrder = [];
  };
}

export default PageStore;
