import { Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { Route, Routes } from 'react-router-dom';

import InnerLayout from '../../../../components/layouts/inner/InnerLayout';
import Header from '../../../../components/shared/Header/ui/Header';
import { Loader } from '../../../../components/shared/Loader/ui/Loader';
import CommonModal from '../../../../components/shared/modal/common/CommonModal';
import { routeConfig } from '../config/routeConfig';

export const AppRouter = () => {
  return (
    <Routes>
      {Object.values(routeConfig).map(({ element, path, authOnly, title }) => (
        <Route
          key={path}
          path={path}
          element={
            authOnly ? (
              <>
                <InnerLayout className={'with-padding'}>
                  <Helmet>
                    <title>{title}</title>
                  </Helmet>
                  <Header />
                  <Suspense fallback={<Loader />}>
                    <>{element}</>
                  </Suspense>
                  <CommonModal />
                </InnerLayout>
              </>
            ) : (
              <>
                <InnerLayout>
                  <Helmet>
                    <title>{title}</title>
                  </Helmet>
                  <Suspense fallback={<Loader />}>
                    <div className='page-wrapper'>{element}</div>
                  </Suspense>
                  <CommonModal />
                </InnerLayout>
              </>
            )
          }
        />
      ))}
    </Routes>
  );
};
