import { toast } from 'react-toastify';
import { apiPost } from '../../api/apiInstance';
import { delCookie, setCookie } from '../../api/cookies';
import { ILogin } from '../../types/LoginPageTypes';

export const auth = async (values: { phone: string }) => {
  // const nav = useNavigate();
  try {
    const res = await apiPost('/auth', values);

    if (res.status === 200) {
      // nav('/login');
    } else {
      toast.error(res.data.message);
    }
  } catch (e) {
    console.error(e);
  }
};

export const login = async (values: ILogin) => {
  const res = await apiPost('/login', values);
  if (res.status === 200) {
    setCookie('fruktonos-auth', res.data.token);
  } else {
    toast.error(res.data.message);
  }
  return res;
};

export const logout = async () => {
  const res = await apiPost('/logout', {});
  if (res.status === 200) {
    delCookie('fruktonos-auth');
    window.location.replace('/auth');
  } else {
    toast.error(res.data.message);
  }
  return res;
};
