import { createContext } from 'react';

export enum Theme {
  MAIN = 'app__main',
  SECONDARY = 'app__secondary'
}

export interface ThemeContextProps {
  isTheme?: Theme;
  setIsTheme?: (isTheme: Theme) => void;
}

export const ThemeContext = createContext<ThemeContextProps>({});

export const LOCAL_STORAGE_THEME_KEY = 'theme';
