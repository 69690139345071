import { AuthPageAsync } from '../../../../pages/AuthPage';
import { CartPageAsync } from '../../../../pages/CartPage';
import { CatalogPageAsync } from '../../../../pages/CatalogPage';
import { ChatPageAsync } from '../../../../pages/ChatPage';
import { CheckoutPageAsync } from '../../../../pages/CheckoutPage';
import { LoginPageAsync } from '../../../../pages/LoginPage';
import { MainPageAsync } from '../../../../pages/MainPage';
import { MarketPageAsync } from '../../../../pages/MarketPage';
import { MyAccountPageAsync } from '../../../../pages/MyAccountPage';
import { NotFoundPageAsync } from '../../../../pages/NotFoundPage';
import { OrderConfirmedPageAsync } from '../../../../pages/OrderConfirmedPage';
import { OrderHistoryPageAsync } from '../../../../pages/OrderHistoryPage';
import { PolicyPageAsync } from '../../../../pages/PolicyPage';
import { SearchPageAsync } from '../../../../pages/SearchPage';
import { UserAgreementPageAsync } from '../../../../pages/UserAgreementPage.tsx';
import { WholesaleBuyersPageAsync } from '../../../../pages/WholesaleBuyersPage';
import {
  getRouteAuth,
  getRouteCart,
  getRouteCatalog,
  getRouteChat,
  getRouteCheckout,
  getRouteLogin,
  getRouteMain,
  getRouteMarket,
  getRouteMyAccount,
  getRouteNotFound,
  getRouteOrderConfirmed,
  getRouteOrderHistory,
  getRoutePolicy,
  getRouteSearch,
  getRouteUserAgreement,
  getRouteWholesaleBuyers
} from '../../../../shared/const';
import { AppRouteProps } from '../../../../types/routeTypes';
import { AppRoutes } from '../model/const';

/*  Роуты приложения */
export const routeConfig: Record<AppRoutes, AppRouteProps> = {
  [AppRoutes.AUTH]: {
    path: getRouteAuth(),
    element: <AuthPageAsync />,
    authOnly: false,
    title: 'Авторизация'
  },
  [AppRoutes.LOGIN]: {
    path: getRouteLogin(),
    element: <LoginPageAsync />,
    authOnly: false,
    title: 'Вход'
  },
  [AppRoutes.MAIN]: {
    path: getRouteMain(),
    element: <MainPageAsync />,
    authOnly: true,
    title: 'Главная'
  },
  [AppRoutes.CART]: {
    path: getRouteCart(),
    element: <CartPageAsync />,
    authOnly: true,
    title: 'Корзина'
  },
  [AppRoutes.MARKET]: {
    path: getRouteMarket(),
    element: <MarketPageAsync />,
    authOnly: true,
    title: 'Рынок'
  },
  [AppRoutes.ACCOUNT]: {
    path: getRouteMyAccount(),
    element: <MyAccountPageAsync />,
    authOnly: true,
    title: 'Мои данные'
  },
  [AppRoutes.ORDER_HISTORY]: {
    path: getRouteOrderHistory(),
    element: <OrderHistoryPageAsync />,
    authOnly: true,
    title: 'История заказов'
  },
  [AppRoutes.CHAT]: {
    path: getRouteChat(),
    element: <ChatPageAsync />,
    authOnly: true,
    title: 'Чат с поддержкой'
  },
  [AppRoutes.CATALOG]: {
    path: getRouteCatalog(),
    element: <CatalogPageAsync />,
    authOnly: true,
    title: 'Каталог'
  },
  [AppRoutes.CHECKOUT]: {
    path: getRouteCheckout(),
    element: <CheckoutPageAsync />,
    authOnly: true,
    title: 'Оформление заказа'
  },
  [AppRoutes.ORDER_CONFIRM]: {
    path: getRouteOrderConfirmed(),
    element: <OrderConfirmedPageAsync />,
    authOnly: true,
    title: 'Заказ успешно оформлен'
  },
  [AppRoutes.WHOLE_SALE]: {
    path: getRouteWholesaleBuyers(),
    element: <WholesaleBuyersPageAsync />,
    authOnly: true,
    title: 'Оптовым покупателям'
  },
  [AppRoutes.SEARCH]: {
    path: getRouteSearch(),
    element: <SearchPageAsync />,
    authOnly: true,
    title: 'Результаты поиска'
  },
  [AppRoutes.USER_AGREEMENT]: {
    path: getRouteUserAgreement(),
    element: <UserAgreementPageAsync />,
    authOnly: true,
    title: 'Пользовательское соглашение'
  },
  [AppRoutes.POLICY]: {
    path: getRoutePolicy(),
    element: <PolicyPageAsync />,
    authOnly: true,
    title: 'Политика конфиденциальности и обработки персональных данных'
  },
  [AppRoutes.NOT_FOUND]: {
    path: getRouteNotFound(),
    element: <NotFoundPageAsync />,
    authOnly: true,
    title: '404'
  }
};
