import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import ReactDOM from 'react-dom/client';

import './index.css';
import App from './App';
import { ThemeProvider } from './app/providers/ThemeProvider';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'mobx-react';
import stores from './store';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});
root.render(
  <>
    <Provider {...stores}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <BrowserRouter>
          <ThemeProvider>
            <App />
          </ThemeProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </Provider>
    <ToastContainer
      position='top-right'
      autoClose={5000}
      closeOnClick
      pauseOnHover
      theme='colored'
    />
  </>
);

reportWebVitals();
