import { useEffect, useState } from 'react';
import ReactSelect, { components } from 'react-select';

import { DropdownWrapper, IconWrap } from './CommonDropdown.styled';

import { DropdownProps } from '../../../../types/CommonTypes';
import { EIcon, Icon } from '../../../icons/icon';

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator
      className={'dropdownArrow'}
      {...props}
    >
      <Icon name={EIcon.arrow} />
    </components.DropdownIndicator>
  );
};

const CommonDropdown: React.FC<DropdownProps> = (props) => {
  const {
    additionalProps,
    labelTitle,
    currentValue,
    options,
    className,
    classNameWrap,
    isMulti,
    disabled,
    onChange,
    placeholder,
    customOption: CustomOption,
    children
  } = props;
  const transformedList = options?.map((option) => {
    if (option.label && option.value) {
      return option;
    } else {
      return { value: option.id, label: option.name || option.title || option?.name || option?.fio, additional: option };
    }
  });
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(
    !!transformedList?.find((option) => option.value === currentValue || option.label === currentValue)
  );

  useEffect(() => {
    setIsFilled(!!transformedList?.find((option) => option.value === currentValue || option.label === currentValue));
  }, [currentValue, transformedList]);
  return (
    <DropdownWrapper className={classNameWrap}>
      <ReactSelect
        options={transformedList}
        value={transformedList?.find((option) => option.value === currentValue || option.label === currentValue)}
        className={`${className ? className : ''} react-select`}
        classNamePrefix={'custom-select'}
        noOptionsMessage={() => 'Нет доступных опций'}
        // components={{ DropdownIndicator: DropdownIndicator, Option: CustomOption }}
        components={{ DropdownIndicator: DropdownIndicator }}
        // components={{ Option: CustomOption }}
        placeholder={placeholder ? placeholder : 'Выберите..'}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        isMulti={isMulti}
        isDisabled={disabled}
        isSearchable={false}
        onChange={onChange}
        {...additionalProps}
      />
      <IconWrap>{children}</IconWrap>
    </DropdownWrapper>
  );
};

export default CommonDropdown;
