import { makeAutoObservable } from 'mobx';

import { RootStore } from '.';
import { FULFILLED, INIT, PENDING, STATE } from '../shared/const/state.const';
import { CartProduct } from '../types/CatalogPageTypes';

class CartStore {
  products: CartProduct[];
  totalPrice: number;
  state: STATE;
  constructor(rootStore: RootStore) {
    this.state = INIT;
    this.products = [];
    this.totalPrice = 0;
    makeAutoObservable(this);
  }

  add = (product: CartProduct) => {
    this.state = PENDING;
    if (this.products.find((item) => item.id === product.id)) {
      const index = this.products.findIndex((item) => item.id === product.id);
      this.products = [
        ...this.products.slice(0, index),
        {
          ...product,
          weight: this.products[index].weight + product.weight
        },
        ...this.products.slice(index + 1)
      ];
    } else {
      this.products = [...this.products, product];
    }
    this.getTotalPrice(this.products);
    this.state = FULFILLED;
  };

  remove = (product: CartProduct) => {
    this.state = PENDING;
    if (this.products.find((item) => item.id === product.id)) {
      const index = this.products.findIndex((item) => item.id === product.id);
      this.products = [...this.products.slice(0, index), ...this.products.slice(index + 1)];
    }
    this.getTotalPrice(this.products);
    this.state = FULFILLED;
  };

  removeAll = () => {
    this.products = [];
    this.getTotalPrice(this.products);
  };

  decrease = (count: number, product: CartProduct) => {
    this.state = PENDING;
    if (this.products.find((item) => item.id === product.id)) {
      const index = this.products.findIndex((item) => item.id === product.id);
      if (this.products[index].weight <= count) {
        const index = this.products.findIndex((item) => item.id === product.id);
        this.products = [...this.products.slice(0, index), ...this.products.slice(index + 1)];
      } else {
        this.products = [
          ...this.products.slice(0, index),
          {
            ...product,
            weight: this.products[index].weight - count
          },
          ...this.products.slice(index + 1)
        ];
      }
      this.getTotalPrice(this.products);
    }

    this.state = FULFILLED;
  };

  increase = (count: number, product: CartProduct) => {
    this.state = PENDING;
    if (this.products.find((item) => item.id === product.id)) {
      const index = this.products.findIndex((item) => item.id === product.id);
      this.products = [
        ...this.products.slice(0, index),
        {
          ...product,
          weight: this.products[index].weight + count
        },
        ...this.products.slice(index + 1)
      ];
      this.getTotalPrice(this.products);
    }
  };

  getTotalPrice = (products: CartProduct[]) => {
    this.totalPrice = products.reduce((acc, val) => (val.saleprice ? acc + val.saleprice * val.weight : acc + val.price * val.weight), 0);
  };
}

export default CartStore;
