import { Link } from 'react-router-dom';

import { styled } from 'styled-components';

import { opacityAnim, openMoveTop } from '../../../../utils/animations';

export const Wrapper = styled.div`
  max-width: 550px;
  width: 100%;
  position: relative;
`;
export const InputWrap = styled.input`
  padding: 16px 18px 16px 38px;
  min-width: 302px;
  max-width: 550px;
  width: 100%;
  background-color: ${(props) => props.theme.color.white};
  border-radius: 32px;
  height: 52px;
  color: ${(props) => props.theme.color.grey6E};
  font-family: 'Gilroy';
  font-size: 14px;
  line-height: normal;
  &::placeholder {
    color: ${(props) => props.theme.color.grey6E};
  }
  &:hover {
    -webkit-box-shadow: 0 0 0 1px ${(props) => props.theme.color.searchBorderHover} inset;
    -moz-box-shadow: 0 0 0 1px ${(props) => props.theme.color.searchBorderHover} inset;
    box-shadow: 0 0 0 1px ${(props) => props.theme.color.searchBorderHover} inset;
  }
  &:focus,
  &.focused {
    -webkit-box-shadow: 0 0 0 2px ${(props) => props.theme.color.searchBorderHover} inset;
    -moz-box-shadow: 0 0 0 2px ${(props) => props.theme.color.searchBorderHover} inset;
    box-shadow: 0 0 0 2px ${(props) => props.theme.color.searchBorderHover} inset;

    &::placeholder {
      opacity: 0;
    }
  }
  @media (max-width: 1280px) {
    padding: 16px 16px 16px 48px;
  }
  @media (max-width: 768px) {
    min-width: auto;
    padding: 10.5px 7px 10.5px 31px;
    height: 40px;
    font-size: 13px;
    background-color: ${(props) => props.theme.color.searchMobileBg};
    color: ${(props) => props.theme.color.text};
  }
`;
export const IconWrap = styled.span`
  display: flex;
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(calc(-50% - 1px));
  color: ${(props) => props.theme.color.grey6E};
  width: 16px;
  height: 16px;
  &.clear-search {
    cursor: pointer;
    left: auto;
    right: 20px;
    opacity: 0;
    animation: ${opacityAnim} 0.3s ease forwards;
  }

  @media (max-width: 768px) {
    left: 11px;
    &.clear-search {
      right: 12px;
    }
  }
`;

export const SearchResult = styled.div`
  padding: 20px;
  position: absolute;
  top: 100%;
  width: 100%;
  border-radius: 8px;
  background-color: ${(props) => props.theme.color.white};
  z-index: 2;
  animation: ${openMoveTop} 0.3s ease;
  max-height: 500px;
  overflow-y: scroll;
  .img {
    width: 60px;
    flex-shrink: 0;
  }
  &.example-enter {
    opacity: 0;
    visibility: hidden;
    transform: translateY(20px);
  }
  &.example-enter-active {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
    transition:
      opacity 300ms,
      transform 300ms;
  }
  &.example-exit {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
  &.example-exit-active {
    opacity: 0;
    transform: translateY(20px);
    visibility: hidden;
    transition:
      opacity 300ms,
      transform 300ms;
  }
  .all-results {
    justify-content: center;
    span {
      color: ${(props) => props.theme.color.mainDark};
      padding: 4px 8px;
      display: flex;
      text-align: center;
      width: fit-content;
      border-radius: 4px;
      font-weight: 500;
      transition: all 0.3s ease;
    }
    &:hover {
      span {
        background-color: ${(props) => props.theme.color.mainDark};
        color: ${(props) => props.theme.color.white};
      }
    }
  }
  @media (max-width: 768px) {
    padding: 20px 10px;
    max-height: 350px;
  }
`;

export const SearchResultList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const SearchResultItem = styled.li`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const SearchShopResultItem = styled.li`
  display: flex;
  gap: 16px;
  align-items: center;
  .img {
    width: 60px;
    height: 60px;
    img {
      border-radius: 8px;
    }
    .without-img {
      justify-content: center;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      background-color: ${(props) => props.theme.color.mainLightHoverThird};
      font-size: 28px;
      font-weight: 700;
      color: ${(props) => props.theme.color.main};
    }
  }
  .shop-name {
    color: ${(props) => props.theme.color.textSecondary};
    font-weight: 600;
  }
  .shop-link {
    margin-left: auto;
    padding: 6px 16px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    gap: 4px;
    background-color: ${(props) => props.theme.color.mainDark};
    color: ${(props) => props.theme.color.white};

    svg {
      transform: rotate(180deg);
    }
    &:hover,
    &:focus {
      background-color: ${(props) => props.theme.color.main};
    }
  }
  @media (max-width: 768px) {
    gap: 12px;
    .shop-name {
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

export const SearchResultShop = styled(Link)`
  display: flex;
  gap: 12px;
`;

export const SearchResultProductsList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const SearchResultProductsItem = styled.li`
  padding: 6px 0;
  display: flex;
  gap: 12px;
  align-items: center;
  color: ${(props) => props.theme.color.textSecondary};
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    background-color: ${(props) => props.theme.color.mainLightHoverSecond};
  }
  .product-name {
    font-size: 14px;
    font-weight: 400;
  }
  .product-price {
    font-size: 16px;
    font-weight: 600;
  }
  .product-unit {
    font-size: 16px;
    color: ${(props) => props.theme.color.grey6E};
  }
  .add-to-cart {
    margin-left: auto;
    min-width: 78px;
    justify-content: center;
    .add-btn {
      display: flex;
      padding: 4px;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      background-color: ${(props) => props.theme.color.orange};
      color: ${(props) => props.theme.color.white};
      &:hover:not(:disabled) {
        svg {
          color: ${(props) => props.theme.color.orange};
        }
        background-color: ${(props) => props.theme.color.white};
      }
      &:disabled {
        opacity: 0.5;
        cursor: default;
      }
    }
  }
  .product-cart-btn {
    height: 33px;
    background-color: ${(props) => props.theme.color.bgSecond};
    .input-count-wrap {
      color: ${(props) => props.theme.color.text};
    }
    button:first-child {
      color: ${(props) => props.theme.color.grey85};
    }
  }
  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: 60px 1fr 1fr;
    row-gap: 0;
    .img {
      grid-column: 1 / 2;
    }
    .product-name-price-wrap {
      grid-column: 2 / 4;
    }
    .add-to-cart {
      grid-column: 1 / 4;
    }
  }
`;

export const EmptySearch = styled.div`
  flex-direction: column;
  gap: 10px;
`;
