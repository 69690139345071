import { ReactNode } from 'react';

import { makeAutoObservable } from 'mobx';

import { RootStore } from '.';
import { ModalSize } from '../shared/ui/Modal';
import { ModalType } from '../shared/ui/Modal/ui/Modal.props';
import { IActionName, IModalName } from '../types/ModalTypes';

class ModalStore {
  rootStore: RootStore;
  showModal: boolean;
  modalName: IModalName;
  modalPayload: object | undefined;
  isDanger: boolean;
  actionName: IActionName;
  classModal: string | undefined;
  modalSize: ModalSize;
  modalHeader: string;
  modalType: ModalType;
  skeleton: ReactNode;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.showModal = false;
    this.modalName = '';
    this.modalPayload = undefined;
    this.isDanger = false;
    this.actionName = '';
    this.classModal = undefined;
    this.modalSize = ModalSize.M;
    this.modalHeader = '';
    this.modalType = ModalType.CENTER;
    this.skeleton = null;
    makeAutoObservable(this);
  }

  openModal = ({
    modalName,
    payload,
    isDanger,
    actionName,
    classModal,
    modalSize,
    modalHeader,
    modalType,
    skeleton
  }: {
    modalName: IModalName;
    payload?: any;
    isDanger?: boolean;
    actionName?: IActionName;
    classModal?: string;
    modalSize?: ModalSize;
    modalHeader?: string;
    modalType?: ModalType;
    skeleton?: ReactNode;
  }) => {
    this.showModal = true;
    this.modalName = modalName;
    if (payload) {
      this.modalPayload = payload;
    }
    if (isDanger) {
      this.isDanger = true;
    }
    if (actionName) {
      this.actionName = actionName;
    }
    if (classModal) {
      this.classModal = classModal;
    }
  };

  closeModal = () => {
    this.showModal = false;
  };
  afterCloseModal = () => {
    this.modalName = '';
    this.modalPayload = undefined;
    this.isDanger = false;
    this.actionName = '';
    this.classModal = undefined;
  };
}

export default ModalStore;
