import styled from 'styled-components';

export const LoaderWrapper = styled.span`
  width: 20px;
  height: 12px;
  display: block;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  background: ${(props) => props.theme.color.main};
  box-sizing: border-box;
  animation: animloader 0.6s 0.3s ease infinite alternate;

  &::after,
  &::before {
    content: '';
    box-sizing: border-box;
    width: 20px;
    height: 12px;
    background: ${(props) => props.theme.color.main};
    position: absolute;
    border-radius: 4px;
    top: 0;
    right: 110%;
    animation: animloader 0.6s ease infinite alternate;
  }

  &::after {
    left: 110%;
    right: auto;
    animation-delay: 0.6s;
  }

  @keyframes animloader {
    0% {
      width: 20px;
    }

    100% {
      width: 48px;
    }
  }
`;
