import styled from 'styled-components';
import { opacityAnim } from '../../../utils/animations';
import { BACKDROP, DURATION } from '../../const/style.const';

interface IOverlay {
  $type?: 'center' | 'side' | 'top' | 'side-overlay';
}

export const OverlayWrapper = styled.div<IOverlay>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 7;
  height: 100%;
  touch-action: none;
  display: ${(props) => (props.$type === 'side' ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;

  background: ${(props) => props.theme.color.modalOverlayBg};
  backdrop-filter: ${BACKDROP};
  pointer-events: auto;
  animation-duration: ${DURATION};
  animation-timing-function: ease-in-out;
  animation-name: ${opacityAnim};
  opacity: 1;
  &.isClosing {
    opacity: 0;
    transition-duration: ${DURATION};
    transition-property: opacity;
    transition-timing-function: ease-in-out;
  }
  //@media(max-width: 767px){
  //  backdrop-filter: blur(15px);
  //}
`;
