import styled from 'styled-components';

import { closeMoveBottom, opacityAnim, openMoveTop } from '../../../../utils/animations';
import { DURATION, DURATION_500, Z_INDEX } from '../../../const/style.const';

interface IModalContent {
  size?: 'l' | 'm' | 's' | 'xs' | 'xxs';
}

export const ModalWrapper = styled.div<IModalContent>`
  bottom: auto;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: flex-start;
  outline: none;
  overflow: auto;
  z-index: ${Z_INDEX};
  &::-webkit-scrollbar {
    display: none;
  }

  &.top {
    margin-top: 0;
  }
  &.open {
    display: flex;
    visibility: visible;
  }
  &.close {
    display: none;
    visibility: hidden;
  }
`;

export const ModalContainer = styled.div<IModalContent>`
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border: 2.5rem solid transparent;
  margin: auto;
  max-width: ${(props) => {
    if (props.size !== undefined) {
      switch (props.size) {
        case 'l':
          return props.size ? '60rem' : '47.5rem';
        case 'm':
          return props.size ? '47.5rem' : '47.5rem';
        case 'xs':
          return props.size ? '35rem' : '47.5rem';
        default:
          return '42.5rem';
      }
    } else {
      return '42.5rem';
    }
  }};
  width: ${(props) => {
    if (props.size !== undefined) {
      switch (props.size) {
        default:
          return '100%';
      }
    } else {
      return '100%';
    }
  }};

  &.top {
    margin-top: 0;
  }
  @media (max-width: 767px) {
    border: none;
    width: 100vw;
    overflow-x: hidden;
    bottom: 0;
    border-radius: ${(props) => {
      if (props.size !== undefined) {
        switch (props.size) {
          case 'l':
            return props.size ? '0' : '0';
          case 'm':
            return props.size ? '0' : '0';
          case 'xs':
            return props.size ? '1rem' : '0';
          case 'xxs':
            return props.size ? '1rem' : '0';

          default:
            return '0';
        }
      } else {
        return '0';
      }
    }};
    margin: ${(props) => {
      if (props.size !== undefined) {
        switch (props.size) {
          case 'l':
            return props.size ? 'unset' : 'unset';
          case 'm':
            return props.size ? 'unset' : 'unset';
          case 'xs':
            return props.size ? 'auto auto 1rem' : 'unset';
          case 'xxs':
            return props.size ? 'auto auto 1rem' : 'unset';

          default:
            return 'unset';
        }
      } else {
        return 'unset';
      }
    }};
    max-width: ${(props) => {
      if (props.size !== undefined) {
        switch (props.size) {
          case 'l':
            return props.size ? '100vw !important' : '100vw !important';
          case 'm':
            return props.size ? '100vw !important' : '100vw !important';
          case 'xs':
            return props.size ? 'calc(100vw - 2rem)' : '47.5rem';
          case 'xxs':
            return props.size ? 'calc(100vw - 2rem)' : '47.5rem';

          default:
            return '42.5rem';
        }
      } else {
        return '42.5rem';
      }
    }};
    min-height: ${(props) => {
      if (props.size !== undefined) {
        switch (props.size) {
          case 'l':
            return props.size ? '100vh !important' : '100vh !important';
          case 'm':
            return props.size ? '100vh !important' : '100vh !important';
          case 'xs':
            return props.size ? 'auto' : '47.5rem';
          case 'xxs':
            return props.size ? 'auto' : '47.5rem';

          default:
            return '42.5rem';
        }
      } else {
        return '42.5rem';
      }
    }};
  }
`;

export const ModalContent = styled.div<IModalContent>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  max-width: ${(props) => {
    if (props.size !== undefined) {
      switch (props.size) {
        default:
          return 'none';
      }
    } else {
      return 'none';
    }
  }};
  z-index: 8;
  overflow: hidden;
  transition: 0.3s ease;
  border-radius: 1rem;
  pointer-events: auto;
  opacity: 1;
  animation-duration: ${DURATION};
  animation-timing-function: ease-in-out;
  animation-name: ${openMoveTop};
  &.isClosing {
    animation-duration: ${DURATION};
    transition-duration: ${DURATION};
    transition-property: visibility;
    transition-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    animation-name: ${closeMoveBottom};
    visibility: hidden;
    *,
    *:before,
    *:after {
      transition-property: visibility;
      transition-duration: ${DURATION};

      transition-timing-function: ease-in-out;
      visibility: hidden;
      opacity: 0;
    }
  }

  @media (max-width: 767px) {
    border: none;
    background: ${(props) => props.size === 'xxs' && 'transparent'};
    justify-content: center;

    padding: ${(props) => {
      if (props.size !== undefined) {
        switch (props.size) {
          case 'l':
            return props.size ? '0 1rem 1rem' : '0 1rem 1rem';
          case 'm':
            return props.size ? '0 1rem 1rem' : '0 1rem 1rem';
          case 'xs':
            return props.size ? '1rem' : '0 1rem 1rem';
          case 'xxs':
            return props.size ? '0' : '0 1rem 1rem';
          default:
            return '0 1rem 1rem';
        }
      } else {
        return '0 1rem 1rem';
      }
    }};
    width: 100vw;
    margin: 0 auto;
    max-width: ${(props) => {
      if (props.size !== undefined) {
        switch (props.size) {
          case 'l':
            return props.size ? '100vw !important' : '100vw !important';
          case 'm':
            return props.size ? '100vw !important' : '100vw !important';
          case 'xs':
            return props.size ? 'calc(100vw - 2rem)' : '100vw';
          case 'xxs':
            return props.size ? 'calc(100vw - 2rem)' : '100vw';
          default:
            return '100vw';
        }
      } else {
        return '100vw';
      }
    }};
    min-height: ${(props) => {
      if (props.size !== undefined) {
        switch (props.size) {
          case 'l':
            return props.size ? '100vh !important' : '100vh !important';
          case 'm':
            return props.size ? '100vh !important' : '100vh !important';
          case 'xs':
            return props.size ? 'auto' : '100vh !important';
          case 'xxs':
            return props.size ? 'auto' : '100vh !important';
          default:
            return '100vh !important';
        }
      } else {
        return '100vh !important';
      }
    }};
    border-radius: ${(props) => {
      if (props.size !== undefined) {
        switch (props.size) {
          case 'l':
            return props.size ? '0' : '0';
          case 'm':
            return props.size ? '0' : '0';
          case 'xs':
            return props.size ? '1rem' : '0';
          case 'xxs':
            return props.size ? '0' : '0';
          default:
            return '0';
        }
      } else {
        return '0';
      }
    }};
    bottom: 0;
  }
`;

export const CloseButton = styled.button`
  background: rgba(104, 104, 104, 0.96);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 1.5rem;
  border: none;
  cursor: pointer;
  outline: none;
  right: 1.5rem;
  animation-duration: ${DURATION_500};
  animation-timing-function: ease-in-out;
  animation-name: ${opacityAnim};
  opacity: 1;
  color: ${(props) => props.theme.color.base01};
  height: 40px;
  width: 40px;
  border-radius: 6.25rem;
  z-index: ${Z_INDEX};
  transition-duration: ${DURATION};
  transition-property: background;
  transition-timing-function: ease-in-out;
  &:hover {
    background: rgba(159, 159, 159, 0.86);
  }
  &.isClosing {
    opacity: 0;
    transition-duration: ${DURATION};
    transition-property: opacity;
    transition-timing-function: ease-in-out;
  }
  &.side {
    top: 175px;
    @media (max-width: 767px) {
      top: 1.5rem;
    }
  }
  &.side-overlay {
    top: 2rem;
  }
  &.side-recording {
    position: relative;
    top: auto;
  }
`;
export const SheetModalWrapper = styled.div`
  padding: 0 1rem 2rem;
  height: 100%;
  overflow-y: auto;
`;
