export const ForwardArrow = () => {
  return (
    <svg
      width='18'
      height='16'
      viewBox='0 0 18 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.2139 8.70703C17.462 8.45894 17.462 8.05669 17.2139 7.80859L13.1709 3.76561C12.9228 3.51751 12.5205 3.51751 12.2724 3.76561C12.0243 4.0137 12.0243 4.41595 12.2724 4.66405L15.8662 8.25781L12.2724 11.8516C12.0243 12.0997 12.0243 12.5019 12.2724 12.75C12.5205 12.9981 12.9228 12.9981 13.1709 12.75L17.2139 8.70703ZM0.764648 8.89311L16.7646 8.89311L16.7646 7.62252L0.764648 7.62252L0.764648 8.89311Z'
        fill='currentColor'
      />
    </svg>
  );
};
