import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 17px);
  &.with-padding {
    padding-top: 134px;
  }
  @media (max-width: 1280px) {
    &.with-padding {
      padding-top: 134px;
    }
  }
  @media (max-width: 768px) {
    &.with-padding {
      padding-top: 134px;
    }
  }
`;
