import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';

interface IModalProps {
  isOpen?: boolean;
  onClose?: () => void;
  animationDelay: number;
}

export const useModal = ({ isOpen, onClose, animationDelay }: IModalProps) => {
  const [isClosing, setIsClosing] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const timerRef = useRef() as MutableRefObject<ReturnType<typeof setTimeout>>;
  useEffect(() => {
    if (isOpen) {
      setIsMounted(true);
      document.body.style.cssText = 'overflow: hidden;position: fixed; left: 0; height: 100vh; width: 100vw;padding-right: 6px; ';
    }
  }, [isOpen]);

  useEffect(() => {
    if (isClosing) {
      document.body.style.cssText = '';
    }
  }, [isClosing]);

  const close = useCallback(() => {
    if (onClose) {
      setIsClosing(true);
      timerRef.current = setTimeout(() => {
        onClose();
        setIsClosing(false);
      }, animationDelay);
    }
  }, [animationDelay, onClose]);

  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        close();
      }
    },
    [close]
  );
  useEffect(() => {
    if (isOpen) {
      window.addEventListener('keydown', onKeyDown);
    }

    return () => {
      clearTimeout(timerRef.current);
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [isOpen, onKeyDown]);

  return {
    isClosing,
    isMounted,
    close
  };
};

export const useModalActions = (setIsModal: (arg: boolean) => void) => {
  const onCloseModal = useCallback(() => {
    setIsModal(false);
  }, [setIsModal]);

  return { onCloseModal };
};
