export function BackArrow() {
  return (
    <svg
      width='18'
      height='16'
      viewBox='0 0 18 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.786131 7.29199C0.538033 7.54009 0.538033 7.94233 0.786131 8.19043L4.82912 12.2334C5.07722 12.4815 5.47946 12.4815 5.72756 12.2334C5.97566 11.9853 5.97566 11.5831 5.72756 11.335L2.13379 7.74121L5.72756 4.14744C5.97566 3.89935 5.97566 3.4971 5.72756 3.249C5.47946 3.0009 5.07722 3.0009 4.82912 3.249L0.786131 7.29199ZM17.2354 7.10592L1.23535 7.10592L1.23535 8.3765L17.2354 8.37651L17.2354 7.10592Z'
        fill='currentColor'
      />
    </svg>
  );
}
