import { makeAutoObservable } from 'mobx';
import { RootStore } from '.';
import { ICreateCheckout } from '../types/CheckoutPageTypes';

class CheckoutStore {
  checkout: ICreateCheckout | null;
  constructor(rootStore: RootStore) {
    this.checkout = null;
    makeAutoObservable(this);
  }

  setCheckout = (data: ICreateCheckout) => {
    this.checkout = data;
  };
}

export default CheckoutStore;
