import styled from 'styled-components';

export const ProductButtonWrapper = styled.div`
  margin-top: auto;
  padding: 6px;
  display: flex;
  justify-content: space-between;
  border-radius: 60px;
  background-color: ${(props) => props.theme.color.inputCountBg};
  color: ${(props) => props.theme.color.main};
  min-height: 32px;
  flex-shrink: 0;
  min-width: 96px;
  transition: all 0.3s ease;
  &:hover {
    background-color: ${(props) => props.theme.color.mainLight};
  }
  .input-count-wrap {
    display: flex;
    align-items: center;
  }

  button {
    padding: 0 6px;
    &:hover,
    &:focus {
      svg {
        transform: scale(1.2);
      }
    }
  }
  .unit {
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
  }
  @media (max-width: 768px) {
    min-height: 32px;
    padding: 6px 10px;
    font-size: 14px;

    button {
      font-size: 14px;
    }
  }
`;

export const ProductButtonInput = styled.input`
  text-align: center;
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  max-width: 50px;
`;

export const ButtonPlus = styled.button`
  color: ${(props) => props.theme.color.main};
`;
export const ButtonMinus = styled.button`
  color: ${(props) => props.theme.color.textSecondary};
`;
