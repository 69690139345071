export function DeliveryIcon() {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.9598 11.164C13.0101 10.1131 13.6001 8.68817 13.6001 7.2024C13.6001 5.71663 13.0101 4.29167 11.9598 3.2408C11.4399 2.72054 10.8225 2.30783 10.143 2.02625C9.46357 1.74466 8.73527 1.59973 7.99976 1.59973C7.26425 1.59973 6.53595 1.74466 5.85648 2.02625C5.177 2.30783 4.55967 2.72054 4.03976 3.2408C2.98944 4.29167 2.39941 5.71663 2.39941 7.2024C2.39941 8.68817 2.98944 10.1131 4.03976 11.164L5.25656 12.3632L6.89096 13.9512L6.99736 14.0456C7.61736 14.548 8.52536 14.516 9.10936 13.9512L11.0574 12.0552L11.9598 11.164ZM7.99976 9.6C7.36324 9.6 6.75279 9.34714 6.30271 8.89706C5.85262 8.44697 5.59976 7.83652 5.59976 7.2C5.59976 6.56348 5.85262 5.95303 6.30271 5.50294C6.75279 5.05286 7.36324 4.8 7.99976 4.8C8.63628 4.8 9.24673 5.05286 9.69682 5.50294C10.1469 5.95303 10.3998 6.56348 10.3998 7.2C10.3998 7.83652 10.1469 8.44697 9.69682 8.89706C9.24673 9.34714 8.63628 9.6 7.99976 9.6Z'
        fill='#0D933F'
      />
    </svg>
  );
}
