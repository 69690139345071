export function BackArrowIcon() {
  return (
    <svg
      width='11'
      height='20'
      viewBox='0 0 11 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.1643 0H10.266C10.8105 0.0605469 11.1741 0.608203 10.9142 1.10664C10.8698 1.1918 10.7599 1.32031 10.5845 1.49219C7.69869 4.32057 4.80756 7.15391 1.91112 9.99219C1.90773 9.99551 1.90584 10 1.90584 10.0048C1.90584 10.0095 1.90773 10.0141 1.91112 10.0176C4.85753 12.9046 7.8062 15.7941 10.7571 18.6863C10.9588 18.8844 11.0306 19.1699 10.9517 19.4414C10.86 19.7565 10.6292 19.9427 10.2592 20H10.1519C9.94757 19.9789 9.76936 19.8937 9.61734 19.7445C6.56568 16.7542 3.51389 13.7641 0.461964 10.7742C0.246685 10.5635 0.114593 10.4048 0.0656899 10.298C-0.0254718 10.0993 -0.0217506 9.8974 0.0768528 9.69219C0.124693 9.59245 0.227814 9.46484 0.386218 9.30938C3.45861 6.29974 6.5306 3.28984 9.60219 0.279687C9.77096 0.114583 9.95833 0.0213542 10.1643 0Z'
        fill='currentColor'
      />
    </svg>
  );
}
