export function AddressIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='23'
      height='20'
      viewBox='0 0 23 20'
      fill='none'
    >
      <path
        d='M3.45 19.55V10.35H0L11.5 0L16.1 4.14V1.15H19.55V7.245L23 10.35H19.55V19.55H13.8V12.65H9.2V19.55H3.45ZM9.2 8.07875H13.8C13.8 7.46542 13.57 6.9621 13.11 6.5688C12.65 6.1755 12.1133 5.97923 11.5 5.98C10.8867 5.98 10.35 6.17665 9.89 6.56995C9.43 6.96325 9.2 7.46618 9.2 8.07875Z'
        fill='#2B2B2B'
      />
    </svg>
  );
}
