import { apiDeleteWithData, apiGet, apiGetWithData, apiPost, apiPut } from '../../api/apiInstance';
import { AddToCartProps, ChangeDeliveryType, GeoTypeData, RemoveFromCartProps } from '../../types/CartPageTypes';
import { GeoType } from '../../types/CommonTypes';

export const getCart = async (params: GeoTypeData) => {
  const res = await apiGetWithData('/cart', params);
  return res?.data || {};
};

export const addToCart = async (data: AddToCartProps) => {
  const { id, geo, quantity } = data;
  const res = await apiPost(`/cart/add/${id}`, { geo: geo, quantity: quantity });
  return res.data;
};

export const updateCartItem = async (data: AddToCartProps) => {
  const { id, geo, quantity } = data;
  const res = await apiPut(`/cart/update/${id}`, { geo: geo, quantity: quantity });
  return res.data;
};
export const removeFromCart = async (data: RemoveFromCartProps) => {
  const { id, geo } = data;

  const res = await apiDeleteWithData(`/cart/remove/${id}`, { geo: geo });
  return res.data;
};

export const clearCart = async (geo: GeoType) => {
  const res = await apiDeleteWithData(`/cart/remove-all`, { geo: geo });
  return res.data;
};

export const changeDeliveryType = async (data: ChangeDeliveryType) => {
  const { geo, delivery_type, ya_price } = data;
  const res = await apiPost('/cart/delivery-type', { geo: geo, delivery_type: delivery_type, ya_price: ya_price });
  return res.data;
};

// export const getPromocode = async (geo: GeoType, code: number) => {
//   const res = await apiPost(`/cart/promocode`, { geo: geo, code: code.toString() });
//   return res.data;
// };

export const promocodeApply = async (code: string, geo: GeoType) => {
  const res = await apiPost('/cart/promocode', { geo: geo, code: code.toString() });
  return res.data;
};

export const promocodeCancel = async (geo: GeoType) => {
  const res = await apiDeleteWithData('/cart/promocode', { geo: geo });
  return res.data;
};

export const getWellcomePromocode = async () => {
  const res = await apiGet('/cart/promocodephone');
  return res.data;
};
