import React, { FC, ReactNode, useMemo, useState } from 'react';

import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import theme from './theme';

import { LOCAL_STORAGE_THEME_KEY, Theme, ThemeContext } from '../lib/ThemeContext';

const defaultTheme: Theme = (localStorage.getItem(LOCAL_STORAGE_THEME_KEY) as Theme) || Theme.MAIN;

const ThemeProvider: FC<{ children: ReactNode; initialTheme?: Theme }> = ({ children, initialTheme = Theme.MAIN }) => {
  const [isTheme, setIsTheme] = useState<Theme>(defaultTheme || initialTheme);

  const defaultProps = useMemo(
    () => ({
      isTheme: isTheme,
      setIsTheme: setIsTheme
    }),
    [isTheme]
  );

  return (
    <StyledThemeProvider theme={theme}>
      <ThemeContext.Provider value={defaultProps}>{children}</ThemeContext.Provider>
    </StyledThemeProvider>
  );
};

export default ThemeProvider;
