import { logout } from '../../../../shared/services/auth.service';
import { MenuDataType } from '../../../../types/CommonTypes';
import { isAuthenticated } from '../../../../utils/helperFunctions';

const menuData: MenuDataType[] = [
  {
    title: 'Рынки',
    to: '/',
    click: true
  },
  {
    title: 'Мои данные',
    to: '/myaccount',
    click: isAuthenticated()
  },
  {
    title: 'Корзина',
    to: '/cart',
    click: true
  },
  {
    title: 'История заказов',
    to: '/orders-history',
    click: isAuthenticated()
  },
  {
    title: 'Оптовым покупателям',
    to: '/wholesale-buyers',
    click: true
  },
  {
    title: 'Выход',
    to: '/logout',
    click: isAuthenticated(),
    onClick: logout
  },
  {
    title: 'Вход',
    to: '/auth',
    click: !isAuthenticated()
  }
];

export default menuData;
