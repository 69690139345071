import React from 'react';
import { cn } from '../../../../shared/lib/classnames.helper';
import { ISupportPhone } from '../../../../types/CommonTypes';
import { FlexWithAlign } from '../../../../utils/styleUtils';
import { Icon, EIcon } from '../../../icons/icon';
import { SupportLink, SupportText, Wrapper } from './SupportPhone.styled';

const SupportPhone: React.FC<ISupportPhone> = (props) => {
  const { isBurgerMenu, onlyText } = props;
  return (
    <Wrapper
      $column
      $gap={'10px'}
      $align={'center'}
      className={cn(isBurgerMenu && 'burger-menu')}
    >
      <FlexWithAlign
        $gap={'6px'}
        $align={'center'}
      >
        {!onlyText && <Icon name={EIcon.support} />}
        <SupportText className={'support-text'}>Служба поддержки</SupportText>
      </FlexWithAlign>
      <SupportLink
        type='tel'
        href='tel:+78122095005'
        target={'_blank'}
        rel='noreferrer'
        className={'support-link'}
      >
        +7 812 209-50-05
      </SupportLink>
    </Wrapper>
  );
};

export default SupportPhone;
