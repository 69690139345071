export function SearchIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='19'
      height='18'
      viewBox='0 0 19 18'
      fill='none'
    >
      <path
        d='M7.99038 15.254C11.8262 15.254 14.9357 12.1444 14.9357 8.30862C14.9357 4.47281 11.8262 1.36328 7.99038 1.36328C4.15458 1.36328 1.04504 4.47281 1.04504 8.30862C1.04504 12.1444 4.15458 15.254 7.99038 15.254Z'
        stroke='currentColor'
        strokeWidth='1.73633'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.8442 16.4259L14.0676 12.6494'
        stroke='currentColor'
        strokeWidth='1.73633'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
