import styled from 'styled-components';

export const MiniCartWrapper = styled.div`
  min-width: 112px;
  flex-shrink: 0;
  a {
    display: flex;
    padding: 12px;
    align-items: center;
    justify-content: center;
    gap: 6px;
    background-color: ${(props) => props.theme.color.orange};
    border-radius: 32px;
    border: 2px solid transparent;
    color: ${(props) => props.theme.color.white};

    &:hover,
    &:focus {
      background-color: ${(props) => props.theme.color.mainLightHover};
      border-color: ${(props) => props.theme.color.mainLightHover};
    }
  }
  @media (max-width: 1024px) {
    margin-left: auto;
  }
  @media (max-width: 768px) {
    min-width: auto;
    width: 40px;
    border-radius: 50%;
    a {
      position: relative;
      padding: 8px;
      gap: 5px;
      height: 40px;
    }
  }
`;

export const IconWrapper = styled.span`
  display: flex;
  svg {
    width: 24px;
    height: 24px;
  }
  @media (max-width: 768px) {
    svg {
      width: 22px;
      height: 22px;
    }
  }
`;

export const PriceWrapper = styled.span`
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const MobileCartNotEmpty = styled.div`
  position: absolute;
  top: -1px;
  right: -1px;
  width: 10px;
  height: 10px;
  background-color: ${(props) => props.theme.color.white};
  border: 1.5px solid ${(props) => props.theme.color.main};
  border-radius: 50%;
`;
