import { apiPatch, apiPost } from '../../api/apiInstance';

export const getSession = async () => {
  const res = await apiPost('/user-session', {});
  return res.data;
};

export const updateSession = async () => {
  const res = await apiPatch('/user-session', {});
  return res.data;
};
