import styled from 'styled-components';

export const DropdownWrapper = styled.div`
  position: relative;
  .react-select {
    max-width: 100%;
    width: fit-content;
    color: ${(props) => props.theme.color.mainDark};
    font-family: 'Gilroy';
    font-size: 14px;
    line-height: normal;
  }
  .custom-select__control {
    border-radius: 24px;
    height: 52px;
    &--menu-is-open,
    &--is-focused {
      border-color: ${(props) => props.theme.color.mainDark};
      box-shadow: 0 0 0 1px ${(props) => props.theme.color.mainDark};
    }
    &:hover,
    &:focus {
      border-color: ${(props) => props.theme.color.mainDark};
    }
  }
  .custom-select__placeholder {
    color: ${(props) => props.theme.color.mainDark};
    margin: 0;
  }
  .custom-select__indicator {
    padding: 12px 10px 12px 6px;
    color: ${(props) => props.theme.color.mainDark};
    cursor: pointer;
    svg {
      transition: all 0.3s ease;
    }
  }
  .custom-select__value-container {
    padding-left: 38px;
  }
  .custom-select__option {
    padding: 8px 16px;
    cursor: pointer;
    font-size: 16px;
    transition: all 0.3s ease;
    &:hover {
      background-color: ${(props) => props.theme.color.mainLightHoverSecond};
    }
    &--is-selected {
      color: ${(props) => props.theme.color.text};
      background-color: ${(props) => props.theme.color.mainLightHoverSecond};
    }
  }
  .custom-select__indicator-separator {
    display: none;
  }
  .custom-select__input-container {
    margin: 0;
    padding: 0;
    color: ${(props) => props.theme.color.mainDark};
  }

  .custom-select__control--menu-is-open {
    .custom-select__placeholder {
      opacity: 0;
    }
    .custom-select__indicator {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  @media (max-width: 768px) {
    .react-select {
      width: 160px;
    }
    .custom-select__control {
      min-height: auto;
      height: 40px;
    }
    .custom-select__value-container {
      padding: 0 0 0 36px;
    }
    .custom-select__indicator {
      padding: 8px 11px 8px 8px;
      svg {
        width: 13px;
        height: 13px;
      }
    }
  }
`;

export const IconWrap = styled.span`
  display: flex;
  position: absolute;
  top: 50%;
  left: 18px;
  transform: translateY(-50%);
  color: ${(props) => props.theme.color.mainDark};
  @media (max-width: 768px) {
    left: 11px;

    svg {
      width: 14px;
      height: 9px;
    }
  }
`;
