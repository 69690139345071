import { PropsWithChildren, Suspense, useEffect, useRef } from 'react';

import { inject, observer } from 'mobx-react';

import useMatchMedia from '../../../../hooks/common/useMatchMedia';
import { Modal } from '../../../../shared/ui/Modal';
import ModalStore from '../../../../store/modalStore';
import { Loader } from '../../Loader/ui/Loader';
import { AddAddressModalAsync } from '../add/AddAddressModal.async';
import { ConfirmModalAsync } from '../confirm/ConfirmModal.async';

interface IModalProps {
  modalStore?: ModalStore;
  children?: PropsWithChildren;
}

const CommonModal: React.FC<IModalProps> = observer((props) => {
  const { children, modalStore } = props;
  const modalRef = useRef(null);
  const {
    showModal,
    modalName,
    modalSize,
    modalHeader,
    modalType,
    modalPayload,
    classModal,
    actionName,
    skeleton,
    closeModal,
    afterCloseModal
  } = modalStore!;
  const [isMobile] = useMatchMedia(['((max-width: 767px))']);
  const modals = {
    ADD_ADDRESS: <AddAddressModalAsync closeModal={closeModal} />,
    CONFIRM: (
      <ConfirmModalAsync
        closeModal={closeModal}
        modalPayload={modalPayload}
        actionName={actionName}
      />
    ),
    '': ''
  };
  useEffect(() => {
    if (showModal) {
      document.body.style.cssText = 'overflow: hidden;position: fixed; left: 0; height: 100vh; width: 100vw;padding-right: 6px; ';
    } else {
      document.body.style.cssText = '';
    }
  }, [showModal]);
  return showModal ? (
    <Modal
      isOpen={showModal}
      onClose={() => closeModal()}
      ref={modalRef}
      type={modalType}
      size={modalSize}
      className={classModal ? classModal : 'common'}
      onAfterClose={afterCloseModal}
    >
      <Suspense fallback={skeleton ? skeleton : <Loader />}>
        <>
          {modals[modalName]}
          {children}
        </>
      </Suspense>
    </Modal>
  ) : (
    <></>
  );
});

export default inject('modalStore')(CommonModal);
