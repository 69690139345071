export enum AppRoutes {
  AUTH = 'auth',
  LOGIN = 'login',
  MAIN = 'main',
  CART = 'cart',
  MARKET = 'market',
  ACCOUNT = 'account',
  ORDER_HISTORY = 'orderhistory',
  CATALOG = 'catalog',
  CHECKOUT = 'checkout',
  ORDER_CONFIRM = 'order_confirm',
  CHAT = 'chat',
  WHOLE_SALE = 'wholesalebuyers',
  SEARCH = 'search',
  USER_AGREEMENT = 'useragreement',
  POLICY = 'policy',
  NOT_FOUND = 'not-found'
  // LOGOUT = 'logout',
  // MARKET = 'market',
  // SHOP = 'shop'
}
