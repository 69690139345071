import { keyframes } from 'styled-components';

export const openMoveTop = keyframes`
  0%{
    transform: translateY(20px);
    opacity: 0;
  }
  100%{
    transform: translateY(0);
    opacity: 1;
  }
`;

export const openMoveXTop = keyframes`
  0%{
    transform: translate(-50%, 20px);
    opacity: 0;
  }
  100%{
    transform: translate(-50%, 0);
    opacity: 1;
  }
`;

export const closeMoveBottom = keyframes`
  0%{
    transform: translateY(0);
    opacity: 1;
  }
  100%{
    transform: translateY(20px);
    opacity: 0;
  }
`;
export const openMoveTopCustom = ($fromYCoord?: number) => keyframes`
  0%{
    transform: translateY( ${$fromYCoord ? $fromYCoord : 20 || 20}px);
    opacity: 0;
  }
  100%{
    transform: translateY(0);
    opacity: 1;
  }
`;

export const openMoveBottom = keyframes<{
  $toYCoord?: number;
}>`
  0%{
    transform: translateY(-${(props) => (props.$toYCoord ? props.$toYCoord : 20) || 20}px);
    opacity: 0;
  }
  100%{
    transform: translateY(0);
    opacity: 1;
  }
`;

export const openMoveRightCustom = ($fromXCoord?: number) => keyframes`
  0%{
    transform: translateX( -${$fromXCoord ? $fromXCoord : 20 || 20}px);
    opacity: 0;
  }
  100%{
    transform: translateX(0);
    opacity: 1;
  }
`;

export const opacityAnim = keyframes`
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
`;
