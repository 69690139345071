export const SuccessIcon = () => {
  return (
    <svg
      width='41'
      height='40'
      viewBox='0 0 41 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.4997 35.4163C29.0141 35.4163 35.9163 28.5141 35.9163 19.9997C35.9163 11.4853 29.0141 4.58301 20.4997 4.58301C11.9853 4.58301 5.08301 11.4853 5.08301 19.9997C5.08301 28.5141 11.9853 35.4163 20.4997 35.4163ZM28.9181 15.5976C29.3864 15.0904 29.3548 14.2995 28.8476 13.8313C28.3404 13.363 27.5495 13.3945 27.0812 13.9018L18.2605 23.4561L13.874 19.1626C13.3807 18.6797 12.5893 18.6882 12.1064 19.1816C11.6235 19.6749 11.632 20.4663 12.1253 20.9492L17.4316 26.143C17.673 26.3793 17.9998 26.5078 18.3376 26.4993C18.6753 26.4907 18.9952 26.3458 19.2244 26.0976L28.9181 15.5976Z'
        fill='#1ECF1A'
      />
    </svg>
  );
};
