import { AddIcon } from './AddIcon';
import { AddressIcon } from './AddressIcon';
import { AppStoreIcon } from './AppStoreIcon';
import { ArrowIcon } from './ArrowIcon';
import { BackArrow } from './BackArrow';
import { BackArrowIcon } from './BackArrowIcon';
import { CartIcon } from './CartIcon';
import { CheckBoxIcon } from './CheckBoxIcon';
import { ClearSearchIcon } from './ClearSearchIcon';
import { ClockIcon } from './ClockIcon';
import { CloseIcon } from './CloseIcon';
import { CloseModalIcon } from './CloseModalIcon';
import { CompleteInputIcon } from './CompleteInputIcon';
import { DeliveryIcon } from './DeliveryIcon';
import { EditIcon } from './EditIcon';
import { ForwardArrow } from './ForwardArrow';
import { FreeDeliveryIcon } from './FreeDeliveryIcon';
import { GooglePlayIcon } from './GooglePlayIcon';
import { Logo } from './LogoIcon';
import { LogoLargeIcon } from './LogoLargeIcon';
import { LogoMobileIcon } from './LogoMobileIcon';
import { MinusIcon } from './MinusIcon';
import { PlusIcon } from './PlusIcon';
import { SearchIcon } from './SearchIcon';
import { SearchLogoIcon } from './SearchLogoIcon';
import { SendMessageIcon } from './SendMessageIcon';
import { SuccessIcon } from './SuccessIcon';
import { SupportIcon } from './SupportIcon';
import { TrashIcon } from './TrashIcon';

export enum EIcon {
  logo,
  logomobile,
  search,
  close,
  cart,
  arrow,
  arrowBack,
  backarrow,
  forwardarrow,
  delivery,
  googleplay,
  appstore,
  freedelivery,
  address,
  clock,
  minus,
  plus,
  clearsearch,
  closeModal,
  searchlogo,
  edit,
  trash,
  add,
  support,
  logolarge,
  success,
  completeInput,
  checkbox,
  sendmessage
}
export type IconProps = {
  name: EIcon;
};

const ICONS: Record<EIcon, any> = {
  [EIcon.logo]: Logo,
  [EIcon.search]: SearchIcon,
  [EIcon.close]: CloseIcon,
  [EIcon.cart]: CartIcon,
  [EIcon.arrow]: ArrowIcon,
  [EIcon.backarrow]: BackArrow,
  [EIcon.delivery]: DeliveryIcon,
  [EIcon.googleplay]: GooglePlayIcon,
  [EIcon.appstore]: AppStoreIcon,
  [EIcon.logomobile]: LogoMobileIcon,
  [EIcon.freedelivery]: FreeDeliveryIcon,
  [EIcon.address]: AddressIcon,
  [EIcon.clock]: ClockIcon,
  [EIcon.arrowBack]: BackArrowIcon,
  [EIcon.minus]: MinusIcon,
  [EIcon.plus]: PlusIcon,
  [EIcon.clearsearch]: ClearSearchIcon,
  [EIcon.closeModal]: CloseModalIcon,
  [EIcon.searchlogo]: SearchLogoIcon,
  [EIcon.edit]: EditIcon,
  [EIcon.trash]: TrashIcon,
  [EIcon.add]: AddIcon,
  [EIcon.forwardarrow]: ForwardArrow,
  [EIcon.support]: SupportIcon,
  [EIcon.logolarge]: LogoLargeIcon,
  [EIcon.success]: SuccessIcon,
  [EIcon.completeInput]: CompleteInputIcon,
  [EIcon.checkbox]: CheckBoxIcon,
  [EIcon.sendmessage]: SendMessageIcon
};

export function Icon(props: IconProps) {
  const { name } = props;

  const ChosenIcon = ICONS[name];

  return <ChosenIcon />;
}
