export function CloseIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='7'
      height='7'
      viewBox='0 0 7 7'
      fill='none'
    >
      <path
        d='M6.5 1L1 6.5'
        stroke='currentColor'
        strokeWidth='0.916667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1 1L6.5 6.5'
        stroke='currentColor'
        strokeWidth='0.916667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
