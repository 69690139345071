import { ForwardedRef, ReactNode } from 'react';

export enum ModalSize {
  L = 'l',
  M = 'm',
  S = 's',
  XS = 'xs',
  XXS = 'xxs'
}
export enum ModalType {
  CENTER = 'center',
  TOP = 'top'
}

export interface ModalProps {
  className?: string;
  children?: ReactNode;
  isOpen?: boolean;
  onClose?: () => void;
  onAfterClose: () => void;
  lazy?: boolean;
  ref?: ForwardedRef<HTMLDivElement>;
  size?: ModalSize;
  type?: ModalType;
}
