export const SearchLogoIcon = () => {
  return (
    <svg
      width='36'
      height='30'
      viewBox='0 0 36 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g
        opacity='0.25'
        clipPath='url(#clip0_2221_5261)'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M15.8444 4.66977C22.3485 4.66977 27.624 9.97587 27.624 16.5176C27.624 23.0593 22.3485 28.3654 15.8444 28.3654C9.34025 28.3654 4.06467 23.0593 4.06467 16.5176C4.06467 9.97587 9.34025 4.66977 15.8444 4.66977Z'
          fill='#F9833C'
        />
        <path
          d='M23.7428 8.57348C21.7206 6.53961 18.9292 5.28212 15.8444 5.28212C9.67728 5.28212 4.67349 10.3121 4.67349 16.5176C4.67349 22.7231 9.67456 27.7531 15.8444 27.7531C18.9292 27.7531 21.7206 26.4956 23.7428 24.4617C25.7649 22.4278 27.0152 19.6203 27.0152 16.5176C27.0152 13.4149 25.7649 10.6073 23.7428 8.57348ZM15.8444 4.05469C19.2663 4.05469 22.362 5.44887 24.6044 7.70417C26.8467 9.95946 28.2329 13.0759 28.2329 16.5149C28.2329 19.9538 26.8467 23.0703 24.6044 25.3255C22.362 27.5808 19.2636 28.975 15.8444 28.975C9.00322 28.975 3.45312 23.3956 3.45312 16.5121C3.45312 9.62869 9.0005 4.05742 15.8444 4.05742V4.05469Z'
          fill='#231F20'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12.862 6.63459C13.4573 6.83688 12.9898 7.68159 11.8183 8.52084C11.1035 9.03204 10.2908 9.42295 9.6331 9.60611C9.25802 10.2895 8.78509 10.9292 8.2116 11.506C7.25759 12.4656 6.12963 13.149 4.92557 13.5536C3.67802 13.9718 2.35437 14.0921 1.06334 13.9117C0.786104 13.8734 0.579538 13.6547 0.544205 13.3895C0.364819 12.0938 0.481691 10.7597 0.900259 9.50497C1.30252 8.29394 1.98201 7.15946 2.93602 6.19993C3.89003 5.2404 5.01798 4.55698 6.22204 4.15239C7.20595 3.82162 8.23878 3.67673 9.26345 3.72047C9.10581 2.49031 9.20094 1.24101 9.7092 0.319758C9.87228 0.0245193 10.2419 -0.0848284 10.5355 0.0764595C10.829 0.240481 10.9377 0.612263 10.7774 0.907502C10.3207 1.74128 10.3289 3.02065 10.5871 4.23441C10.8073 5.26227 11.1932 6.2136 11.6118 6.77401C12.1363 6.59905 12.5903 6.53891 12.8648 6.63459'
          fill='#231F20'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M9.0895 7.81281C9.39663 6.89155 9.51351 5.91836 9.44284 4.9561C8.48611 4.88502 7.51852 5.00257 6.60256 5.31148C5.57789 5.65593 4.61301 6.24094 3.79218 7.06651C2.97135 7.89209 2.38971 8.86255 2.04724 9.89315C1.74011 10.8144 1.62324 11.7876 1.69391 12.7499C2.65063 12.8209 3.61823 12.7034 4.53418 12.3945C5.55886 12.05 6.52374 11.465 7.34457 10.6394C8.16539 9.81387 8.74704 8.84341 9.0895 7.81281Z'
          fill='#0DB14B'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M27.3525 10.5495C26.1756 11.7414 25.5695 13.2231 25.4581 14.7238C25.3847 15.7298 25.5314 16.7468 25.8739 17.6899C26.0805 18.253 26.3577 18.7916 26.6975 19.2836C28.4152 18.5264 29.535 17.2361 28.8093 15.0628C28.6707 14.65 28.6272 13.9174 29.236 13.9666C29.8177 14.0131 30.663 14.5544 30.8804 15.1858C31.1359 15.0929 31.0272 13.1848 31.8888 12.6353C32.6716 12.135 33.0303 12.9661 33.2124 13.1547C33.3782 13.3269 33.9843 12.6353 34.3893 12.9798C34.884 13.5292 34.5035 14.3575 34.5877 14.497C34.7209 14.7238 35.2998 14.0049 35.6423 14.8414C36.1424 17.7036 33.3239 20.4947 29.7905 21.692C30.3558 21.8998 30.9701 22.0365 31.6278 22.0857C31.9622 22.1103 32.2149 22.4055 32.1905 22.7418C32.1796 22.8812 32.1252 23.0069 32.0383 23.1026C30.4782 25.1857 28.4505 26.8943 26.1131 28.0725C23.8436 29.2152 21.2887 29.8603 18.5843 29.8603C16.3094 29.8603 14.1377 29.4038 12.1536 28.5782C10.0934 27.7198 8.23972 26.4651 6.69047 24.9069C6.47032 24.6882 6.45401 24.341 6.63883 24.1004L8.85126 21.0059L6.64155 17.0557C6.47576 16.7604 6.58176 16.3859 6.8753 16.2219C6.92694 16.1918 6.9813 16.1727 7.03838 16.159L10.8816 14.9863L10.7783 10.5632C10.7701 10.2269 11.0365 9.94535 11.3708 9.93715C11.4442 9.93715 11.5122 9.94535 11.5774 9.96722L15.8038 11.3915L18.4702 7.47956C18.6604 7.20073 19.0382 7.12965 19.3154 7.31828C19.3915 7.37022 19.4513 7.43582 19.4948 7.50963L21.9111 11.129C22.3107 10.9951 22.9059 10.8037 23.5473 10.596C24.852 10.175 26.3523 9.69112 26.6975 9.54897C27.0073 9.42322 27.3634 9.57084 27.4884 9.88521C27.5835 10.1176 27.5237 10.3773 27.3579 10.5468L27.3525 10.5495Z'
          fill='#231F20'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M20.4787 18.305C21.1664 18.305 21.816 19.1442 21.9301 20.1803C22.0443 21.2164 21.5822 22.0556 20.8946 22.0556C20.2069 22.0556 19.5573 21.2164 19.4432 20.1803C19.329 19.1442 19.7911 18.305 20.4787 18.305ZM23.6261 17.6243C24.1643 17.6243 24.7025 18.2831 24.8221 19.0978C24.9444 19.9124 24.6046 20.5712 24.0637 20.5712C23.5229 20.5712 22.9874 19.9124 22.8678 19.0978C22.7455 18.2831 23.0853 17.6243 23.6261 17.6243ZM24.8465 18.3979C24.3383 17.1486 24.129 15.7736 24.2676 14.4177L22.2101 15.6533C22.0171 15.7927 21.7535 15.8091 21.536 15.6752L19.5872 14.4696L18.2582 16.4953C18.1304 16.7085 17.8776 16.8288 17.6194 16.7796L15.2521 16.3012L15.0238 18.6959C15.0102 18.9419 14.8498 19.1634 14.6025 19.2454L12.3683 19.9725L13.181 22.1704C13.2788 22.3973 13.2326 22.6734 13.0451 22.8539L11.5285 24.3164L13.2163 26.1589C13.3957 26.3557 13.4338 26.6564 13.287 26.8942L12.882 27.5503C13.6702 27.862 14.4883 28.1107 15.3363 28.2912C16.3828 28.5153 17.4699 28.6301 18.5897 28.6301C20.3483 28.6301 22.0334 28.3404 23.6044 27.8046C20.8728 27.5941 19.378 26.6236 18.6849 26.0058L17.8695 26.9298C17.6602 27.1676 17.296 27.1922 17.0595 26.979C16.8231 26.7685 16.8013 26.4022 17.0106 26.1644L18.2418 24.7702C18.4511 24.5323 18.8153 24.5105 19.0518 24.721C19.0871 24.7538 19.117 24.7866 19.1442 24.8248C19.1442 24.8248 20.6527 27.1047 26.1158 26.6209C26.1566 26.6181 26.1946 26.6181 26.2327 26.6209C26.9584 26.2108 27.646 25.7434 28.2929 25.2267C28.1516 25.0572 28.0293 24.8713 27.9287 24.6718C27.7167 24.2508 27.5944 23.7696 27.5944 23.2584C27.5944 22.9359 27.6433 22.6215 27.7357 22.3235C27.7629 22.2333 27.7955 22.1431 27.8336 22.0556C26.4691 21.159 25.4499 19.8605 24.852 18.3897L24.8465 18.3979Z'
          fill='#F9E8BD'
        />
      </g>
      <defs>
        <clipPath id='clip0_2221_5261'>
          <rect
            width='36'
            height='30'
            fill='white'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
