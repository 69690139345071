export function MinusIcon() {
  return (
    <svg
      width='9'
      height='2'
      viewBox='0 0 9 2'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9 2H0V0H9V2Z'
        fill='currentColor'
      />
    </svg>
  );
}
