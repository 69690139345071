import { makeAutoObservable } from 'mobx';
import { createContext, useContext } from 'react';
import CartStore from './cartStore';
import CheckoutStore from './checkoutStore';
import ModalStore from './modalStore';
import PageStore from './pageStore';

export class RootStore {
  cartStore: CartStore;
  pageStore: PageStore;
  modalStore: ModalStore;
  checkoutStore: CheckoutStore;
  constructor() {
    this.cartStore = new CartStore(this);
    this.pageStore = new PageStore(this);
    this.modalStore = new ModalStore(this);
    this.checkoutStore = new CheckoutStore(this);
    makeAutoObservable(this);
  }
}

const rootStore = new RootStore();
const stores = {
  rootStore,
  cartStore: rootStore.cartStore,
  pageStore: rootStore.pageStore,
  modalStore: rootStore.modalStore,
  checkoutStore: rootStore.checkoutStore
};

export const StoreContext = createContext(stores);

export const useStore = () => {
  return useContext<typeof stores>(StoreContext);
};

export default stores;
