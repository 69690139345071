export function ArrowIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
    >
      <path
        d='M5.51465 8.7041L10.7237 13.9131L15.9327 8.7041'
        stroke='currentColor'
        strokeWidth='1.73633'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
