import { apiDelete, apiGet, apiPost, apiPut } from '../../api/apiInstance';
import { ProfileUpdateAddressData, ProfileUpdateDataType } from '../../types/ProfilePageTypes';

export const getProfileData = async () => {
  const res = await apiGet('/profile');

  return res.data;
};

export const updateUserData = async (data: ProfileUpdateDataType) => {
  const res = await apiPut('/profile', data);

  return res;
};

export const addUserAddress = async (data: ProfileUpdateAddressData) => {
  const res = await apiPost('/profile/address', data);

  return res;
};

export const updateUserAddress = async (data: ProfileUpdateAddressData) => {
  const { id, ...values } = data;
  const res = await apiPut(`/profile/address/${id}`, values);

  return res;
};

export const deleteUserAddress = async (id: number) => {
  const res = await apiDelete(`/profile/address/${id}`);

  return res;
};
