export function ClearSearchIcon() {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.83334 12.1667L8.00001 8.00004M8.00001 8.00004L12.1667 3.83337M8.00001 8.00004L3.83334 3.83337M8.00001 8.00004L12.1667 12.1667'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
      />
    </svg>
  );
}
