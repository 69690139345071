import { styled } from 'styled-components';
import { FlexWithAlign } from '../../../../utils/styleUtils';

export const Wrapper = styled(FlexWithAlign)`
  @media (max-width: 1280px) {
    &.burger-menu {
      margin-top: 10px;
      .support-text {
        font-size: 16px;
        line-height: 18.56px;
        color: ${(props) => props.theme.color.white};
      }
      .support-link {
        font-size: 18px;
        font-weight: 500;
        line-height: 21.19px;

        color: ${(props) => props.theme.color.white};
      }
    }
  }
`;

export const SupportText = styled.span`
  font-size: 20px;
  line-height: 23.2px;
`;

export const SupportLink = styled.a`
  font-size: 20px;
  font-weight: 700;
  line-height: 24.18px;
`;
