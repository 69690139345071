import { styled } from 'styled-components';

export const MobileMenuNav = styled.nav`
  display: none;
  color: ${(props) => props.theme.color.white};
  .mobile-menu-wrapper {
    position: fixed;
    top: 166px;
    left: 0;
    padding: 30px 50px 62px 50px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    background-color: ${(props) => props.theme.color.main};
    z-index: 10;
  }
  .mobile-menu-item {
    a {
      font-size: 25px;
      font-weight: 500;
      line-height: 182.2%; /* 45.55px */
    }
  }
  @media (max-width: 1280px) {
    display: flex;
    flex-direction: column;
    position: relative;
    // margin-right: auto;
    .mobile-menu-wrapper {
      padding: 30px 30px 62px 30px;
      top: 102px;
    }
  }
  @media (max-width: 1024px) {
    margin-right: 0;
  }
  @media (max-width: 768px) {
    .mobile-menu-wrapper {
      padding: 20px 20px 62px 20px;
      top: 74px;
      right: 0;
    }
  }
`;

export const MobileMenuList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
