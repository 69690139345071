import { useEffect, useRef, useState } from 'react';
import { Squash as Hamburger } from 'hamburger-react';
import { MobileMenuList, MobileMenuNav } from './MobileMenu.styled';
import { useClickAway } from 'react-use';
import menuData from '../../../menu/ui/Menu.data';
import { Link } from 'react-router-dom';
import { generateRandomString, isAuthenticated } from '../../../../../utils/helperFunctions';
import { EIcon, Icon } from '../../../../icons/icon';
import { AnimatePresence, motion } from 'framer-motion';
import { MenuDataType } from '../../../../../types/CommonTypes';
import SupportPhone from '../../../components/support-phone/SupportPhone';

const MobileMenu = () => {
  const [isOpen, setOpen] = useState(false);
  const ref = useRef(null);
  const [menu, setMenu] = useState<MenuDataType[]>(
    menuData
      .map((item) => {
        return {
          ...item,
          click:
            item.to === '/myaccount' || item.to === '/orders-history' || item.to === '/logout'
              ? isAuthenticated()
              : item.to === '/auth'
                ? !isAuthenticated()
                : item.click
        };
      })
      .filter((item) => item.click)
  );

  useEffect(() => {
    setMenu(
      menuData
        .map((item) => {
          return {
            ...item,
            click:
              item.to === '/myaccount' || item.to === '/orders-history' || item.to === '/logout'
                ? isAuthenticated()
                : item.to === '/auth'
                  ? !isAuthenticated()
                  : item.click
          };
        })
        .filter((item) => item.click)
    );
  }, []);
  useClickAway(ref, () => setOpen(false));

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isOpen]);

  return (
    <MobileMenuNav ref={ref}>
      <Hamburger
        toggled={isOpen}
        size={26}
        toggle={setOpen}
        rounded
        label='Открыть меню'
      />
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className='mobile-menu-wrapper'
          >
            <Link
              to={'/'}
              className='flex'
              onClick={() => setOpen(false)}
            >
              <Icon name={EIcon.logomobile} />
            </Link>
            <MobileMenuList>
              {menu.map((menuitem, index) => (
                <motion.li
                  key={generateRandomString()}
                  initial={{ scale: 0, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{
                    type: 'Tween',
                    stiffness: 200,
                    damping: 20,
                    delay: 0.1 + index / 10
                  }}
                  className='mobile-menu-item'
                >
                  <Link
                    to={menuitem.to}
                    onClick={() => setOpen(false)}
                  >
                    {menuitem.title}
                  </Link>
                </motion.li>
              ))}
            </MobileMenuList>
            <SupportPhone
              onlyText={true}
              isBurgerMenu={true}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </MobileMenuNav>
  );
};

export default MobileMenu;
