import React, { forwardRef } from 'react';

import { ModalProps, ModalType } from './Modal.props';
import { ModalContainer, ModalContent, ModalWrapper } from './Modal.styled';

import { useModal } from '../../../../hooks/common/useModal';
import { ANIMATION_DELAY } from '../../../const/style.const';
import { cn } from '../../../lib/classnames.helper';
import { Overlay } from '../../Overlay/Overlay';
import { Portal } from '../../Portal';

export const Modal = forwardRef<HTMLDivElement, ModalProps>((props, ref) => {
  const { className, children, isOpen, onClose, lazy, type, size } = props;

  const { close, isClosing, isMounted } = useModal({
    animationDelay: ANIMATION_DELAY,
    onClose,
    isOpen
  });

  if (lazy && !isMounted) {
    return null;
  }

  return (
    <>
      <Portal element={document.getElementById('app') ?? document.body}>
        <ModalWrapper
          className={cn(type === ModalType.TOP && 'top', className)}
          ref={ref}
        >
          <Overlay
            type={type}
            className={cn({ isClosing })}
            onClick={close}
          />

          <ModalContainer
            size={size}
            className={cn(type === ModalType.TOP && 'top')}
          >
            <ModalContent
              className={cn({ isClosing })}
              size={size}
            >
              {children}
            </ModalContent>
          </ModalContainer>
        </ModalWrapper>
      </Portal>
    </>
  );
});

Modal.displayName = 'Modal';
