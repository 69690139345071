import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { getCookie } from '../../../api/cookies';

import { PROFILE, PROFILE_ACTIVE_ADDRESS, PROFILE_ADDRESS } from '../../../shared/const/query.const';
import { getProfileData } from '../../../shared/services/profile.service';
import { ProfileAddressType, ProfileDataType } from '../../../types/ProfilePageTypes';

export const useProfileQuery = () => {
  return useQuery({
    queryKey: [PROFILE],
    queryFn: () => getProfileData(),
    staleTime: 50000,
    select: (data: ProfileDataType) => data
  });
};

// export const useProfileAddressQuery = () => {
//   return useQuery({
//     queryKey: [PROFILE_ADDRESS],
//     queryFn: () => getProfileData(),
//     staleTime: 50000000,
//     retry: 1,
//     select: (data: ProfileDataType) => data.addresses
//   });
// };

export const useActiveAddressQuery = () => {
  const [isUnauthenticated, setIsUnauthenticated] = useState(false);

  const { data, error, isError } = useQuery({
    queryKey: [PROFILE_ACTIVE_ADDRESS],
    queryFn: async () => {
      const data = await getProfileData();
      if (data.message === 'Unauthenticated') {
        throw new Error('Unauthenticated');
      }
      return data;
    },
    staleTime: 50000000,
    retry: 1,
    select: (data: ProfileDataType): ProfileAddressType => data.addresses.find((address) => address.active) || {},
    enabled: !isUnauthenticated
  });

  useEffect(() => {
    if (isError && error instanceof Error && error.message === 'Unauthenticated') {
      setIsUnauthenticated(true);
    }
  }, [isError, error]);
  return { data, error, isError };
};

export const useProfileAddressQuery = () => {
  const isAuthenticated = getCookie('fruktonos-auth');
  const queryClient = useQueryClient();

  const profileAddressData = useQuery({
    queryKey: [PROFILE_ADDRESS],
    queryFn: async () => {
      const data = await getProfileData();
      if (data.message === 'Unauthenticated') {
        throw new Error('Unauthenticated');
      }
      return data;
    },
    staleTime: 50000000,
    select: (data: ProfileDataType) => data.addresses
  });

  useEffect(() => {
    if (isAuthenticated) {
      queryClient.invalidateQueries({ queryKey: [PROFILE_ADDRESS] });
    }
  }, [isAuthenticated, queryClient]);

  return profileAddressData;
};
