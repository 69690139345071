import { useQuery } from '@tanstack/react-query';
import { CART, WELLCOME_PROMOCODE } from '../../../shared/const/query.const';
import { getCart, getWellcomePromocode } from '../../../shared/services/cart.service';
import { CartDataType, GeoTypeData } from '../../../types/CartPageTypes';

export const useCartQuery = (data: GeoTypeData) => {
  return useQuery<CartDataType>({
    queryKey: [CART, data],
    queryFn: () => getCart(data),
    staleTime: 1800000,
    select: (data: CartDataType) => data || []
  });
};

export const useWellcomePromocodeQuery = () => {
  return useQuery({
    queryKey: [WELLCOME_PROMOCODE],
    queryFn: () => getWellcomePromocode(),
    select: (data) => data || []
  });
};
