import dayjs from 'dayjs';

import { getCookie, setCookie } from '../api/cookies';
import { IAddressSuggest } from '../types/AddressTypes';
import { TimeSlotType } from '../types/CartPageTypes';

export const generateRandomString = () => Math.random().toString(36).substring(3, 16);

export const isAuthenticated = () => {
  const token = getCookie('fruktonos-auth');

  return !!token;
};

export function getScrollbarWidth() {
  // Creating invisible container
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement('div');
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  // Removing temporary elements from the DOM
  if (outer?.parentNode) {
    outer?.parentNode.removeChild(outer);
  }

  return scrollbarWidth;
}

export function getCountSearchItems(data: any) {
  if (!data) return 0;

  return data.map((item: any) => item.products).reduce((acc: number, val: any) => val.length + acc, 0);
}

export function transformTimeSlots(timeSlots: TimeSlotType[]) {
  if (!timeSlots) return [];
  const resultTimeSlotsSet = new Set(timeSlots.map((timeSlot) => dayjs(timeSlot.time_from).format('YYYY-MM-DD')));
  const resultUniqueArray = Array.from(resultTimeSlotsSet).map((item) => {
    return {
      label: dayjs(item).isSame(dayjs().format('YYYY-MM-DD')) ? 'Сегодня' : 'Завтра',
      value: item
    };
  });

  return resultUniqueArray;
}

export const transformSuggestAddress = (item: IAddressSuggest) => {
  let fullAddress = '';
  if (item.city) fullAddress += item.city;
  if (item.street) fullAddress += `,${item.street}`;
  if (item.house) fullAddress += `,${item.house}`;

  return fullAddress;
};

export const removeSpacesFromString = (value: string, sym: string) => {
  return value
    .split(sym)
    .map((word) => word.trim())
    .join(sym);
};

export const checkIsAuthUser = () => {
  const authData = getCookie('fruktonos-auth');
  if (authData) {
    return true;
  } else {
    setCookie('fruktonos-checkout', 'true');
    return false;
  }
};

export const roundQuantity = (value: string, step: number) => {
  if (step !== 0) {
    const quotientFloor = Math.round(Number(value) / step);
    if (quotientFloor === 0) {
      return step.toString();
    } else {
      return Number((quotientFloor * step).toFixed(1)).toString();
    }
  } else {
    return value;
  }
};

export const generateTimeSlots = () => {
  const today = dayjs().startOf('day').hour(8);
  const tomorrowEnd = dayjs().add(1, 'day').startOf('day').hour(21);

  let currentSlot = dayjs().isBefore(today)
    ? today
    : dayjs()
        .add(2 - (dayjs().hour() % 2), 'hours')
        .startOf('hour');

  const timeSlots = [];

  while (currentSlot.isBefore(tomorrowEnd)) {
    const timeFrom = currentSlot.toISOString().replace(/\.\d{3}Z$/, '.000000Z');
    const timeTo = currentSlot
      .add(2, 'hours')
      .toISOString()
      .replace(/\.\d{3}Z$/, '.000000Z');

    // Только слоты с 9 утра до 10 вечера включительно
    if (currentSlot.hour() >= 9 && currentSlot.hour() <= 21) {
      timeSlots.push({
        time_from: timeFrom,
        time_to: timeTo
      });
    }

    currentSlot = currentSlot.add(2, 'hours');
  }

  return timeSlots;
};
