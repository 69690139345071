export const LogoLargeIcon = () => {
  return (
    <svg
      width='224'
      height='189'
      viewBox='0 0 224 189'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M97.7765 29.5457C139.124 29.5457 172.661 63.132 172.661 104.54C172.661 145.947 139.124 179.533 97.7765 179.533C56.4291 179.533 22.8916 145.947 22.8916 104.54C22.8916 63.132 56.4291 29.5457 97.7765 29.5457Z'
        fill='#F9833C'
      />
      <path
        d='M147.988 54.2552C135.133 41.3813 117.388 33.4217 97.7765 33.4217C58.5716 33.4217 26.7619 65.2603 26.7619 104.54C26.7619 143.819 58.5544 175.657 97.7765 175.657C117.388 175.657 135.133 167.698 147.988 154.824C160.843 141.95 168.791 124.179 168.791 104.54C168.791 84.8999 160.843 67.1291 147.988 54.2552ZM97.7765 25.6523C119.53 25.6523 139.21 34.4772 153.465 48.7527C167.72 63.0282 176.532 82.7543 176.532 104.522C176.532 126.29 167.72 146.016 153.465 160.292C139.21 174.567 119.513 183.392 97.7765 183.392C54.2866 183.392 19.0039 148.075 19.0039 104.505C19.0039 60.9344 54.2693 25.6696 97.7765 25.6696V25.6523Z'
        fill='black'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M78.823 41.983C82.607 43.2635 79.6351 48.6103 72.1881 53.9225C67.6438 57.1582 62.4776 59.6327 58.2962 60.792C55.9117 65.1179 52.9053 69.167 49.2595 72.818C43.1948 78.8916 36.0242 83.2175 28.3698 85.7784C20.439 88.4259 12.0244 89.1872 3.81711 88.0452C2.05471 87.803 0.741544 86.4187 0.516924 84.7402C-0.623456 76.5383 0.119518 68.0941 2.7804 60.1518C5.33762 52.4863 9.65724 45.3053 15.722 39.2317C21.7867 33.1581 28.9573 28.8322 36.6117 26.2713C42.8665 24.1776 49.4323 23.2605 55.9463 23.5373C54.9442 15.7507 55.5489 7.84295 58.78 2.01163C59.8167 0.142838 62.1666 -0.549306 64.0326 0.471607C65.8987 1.50982 66.5899 3.86312 65.5704 5.73191C62.6676 11.0095 62.7195 19.1076 64.3609 26.7904C65.7605 33.2966 68.214 39.3182 70.8749 42.8655C74.2097 41.758 77.0952 41.3774 78.8403 41.983'
        fill='black'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M54.8405 49.4408C56.7929 43.6095 57.5359 37.4494 57.0867 31.3586C51.0047 30.9087 44.8535 31.6527 39.0307 33.608C32.5167 35.7883 26.3828 39.4913 21.1647 44.7169C15.9466 49.9426 12.249 56.0854 10.0719 62.6089C8.11946 68.4402 7.37648 74.6003 7.82572 80.6912C13.9077 81.1411 20.0589 80.397 25.8817 78.4417C32.3957 76.2614 38.5296 72.5585 43.7477 67.3328C48.9658 62.1071 52.6634 55.9643 54.8405 49.4408Z'
        fill='#0D933F'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M170.934 66.7684C163.452 74.3128 159.599 83.6913 158.891 93.191C158.424 99.5587 159.357 105.996 161.534 111.965C162.847 115.53 164.61 118.939 166.77 122.053C177.69 117.26 184.808 109.093 180.195 95.3366C179.314 92.7238 179.037 88.0864 182.908 88.3979C186.605 88.6921 191.979 92.1182 193.361 96.1153C194.985 95.527 194.294 83.4491 199.771 79.971C204.748 76.8045 207.028 82.0648 208.186 83.2587C209.24 84.3488 213.093 79.971 215.668 82.1513C218.812 85.6293 216.393 90.8723 216.929 91.7548C217.776 93.191 221.456 88.6401 223.633 93.9351C226.812 112.052 208.895 129.719 186.432 137.298C190.026 138.613 193.931 139.478 198.113 139.79C200.238 139.945 201.845 141.814 201.689 143.943C201.62 144.825 201.275 145.621 200.722 146.227C190.804 159.412 177.914 170.227 163.055 177.685C148.627 184.917 132.385 189.001 115.193 189.001C100.731 189.001 86.9257 186.111 74.3124 180.886C61.2153 175.452 49.4314 167.51 39.5827 157.647C38.1831 156.263 38.0794 154.065 39.2544 152.542L53.3191 132.955L39.2717 107.951C38.2177 106.082 38.8915 103.712 40.7576 102.673C41.0859 102.483 41.4315 102.362 41.7943 102.275L66.2261 94.8521L65.5695 66.8549C65.5177 64.7265 67.211 62.9443 69.3362 62.8924C69.8027 62.8924 70.2347 62.9443 70.6494 63.0827L97.5174 72.0979L114.468 47.3364C115.677 45.5714 118.079 45.1215 119.841 46.3155C120.325 46.6443 120.705 47.0596 120.982 47.5267L136.342 70.4367C138.882 69.5889 142.666 68.3776 146.744 67.0625C155.037 64.3978 164.575 61.335 166.77 60.4353C168.739 59.6393 171.003 60.5737 171.798 62.5636C172.402 64.0344 172.022 65.6782 170.968 66.7511L170.934 66.7684Z'
        fill='black'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M127.236 115.859C131.608 115.859 135.737 121.171 136.463 127.729C137.189 134.287 134.251 139.599 129.88 139.599C125.509 139.599 121.379 134.287 120.653 127.729C119.928 121.171 122.865 115.859 127.236 115.859ZM147.245 111.55C150.666 111.55 154.087 115.72 154.847 120.877C155.625 126.033 153.465 130.203 150.027 130.203C146.588 130.203 143.184 126.033 142.424 120.877C141.647 115.72 143.806 111.55 147.245 111.55ZM155.003 116.447C151.772 108.539 150.441 99.8356 151.323 91.253L138.243 99.0742C137.016 99.9567 135.34 100.061 133.958 99.2127L121.569 91.5818L113.12 104.404C112.308 105.753 110.701 106.515 109.059 106.203L94.0099 103.175L92.5585 118.333C92.4721 119.89 91.4527 121.292 89.8803 121.811L75.6774 126.414L80.8437 140.326C81.4657 141.762 81.172 143.51 79.9798 144.652L70.3384 153.909L81.0683 165.572C82.2087 166.818 82.4506 168.721 81.5175 170.227L78.9431 174.38C83.9538 176.352 89.1546 177.927 94.5455 179.069C101.198 180.488 108.109 181.215 115.228 181.215C126.407 181.215 137.12 179.38 147.107 175.989C129.742 174.656 120.239 168.514 115.833 164.603L110.649 170.452C109.319 171.957 107.003 172.113 105.5 170.763C103.997 169.431 103.859 167.112 105.189 165.607L113.016 156.782C114.347 155.276 116.662 155.138 118.165 156.47C118.39 156.678 118.58 156.886 118.753 157.128C118.753 157.128 128.342 171.559 163.072 168.496C163.331 168.479 163.573 168.479 163.815 168.496C168.428 165.901 172.8 162.942 176.912 159.671C176.014 158.599 175.236 157.422 174.597 156.159C173.249 153.494 172.471 150.449 172.471 147.213C172.471 145.171 172.783 143.181 173.37 141.295C173.543 140.724 173.75 140.153 173.992 139.599C165.318 133.924 158.839 125.704 155.037 116.395L155.003 116.447Z'
        fill='#F9E8BD'
      />
    </svg>
  );
};
