import { useQuery } from '@tanstack/react-query';
import { PRODUCTS_SEARCH } from '../../../shared/const/query.const';
import { productsSearch } from '../../../shared/services/products.service';
import { ISearchData } from '../../../types/SearchPageTypes';

export const useProductsSearchQuery = (search: string) => {
  return useQuery({
    queryKey: [PRODUCTS_SEARCH, search],
    queryFn: () => productsSearch(search),
    select: (data: ISearchData[]) => data,
    enabled: !!search
  });
};
