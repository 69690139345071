export function CartIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='26'
      height='22'
      viewBox='0 0 26 22'
      fill='none'
    >
      <g clipPath='url(#clip0_38_1049)'>
        <path
          d='M12.8819 11.5699C16.2931 11.5699 19.7043 11.5699 23.1155 11.5699C23.4602 11.5699 23.4621 11.5699 23.3697 11.8937C22.5069 14.9125 21.6441 17.9313 20.7794 20.9501C20.6585 21.373 20.4233 21.5501 19.9852 21.5501C15.2494 21.5501 10.5135 21.5501 5.7777 21.5501C5.32536 21.5501 5.09776 21.3768 4.9711 20.9358C4.09783 17.8885 3.22837 14.8401 2.34653 11.7946C2.28273 11.5746 2.35129 11.567 2.52842 11.567C5.97959 11.5699 9.42981 11.5699 12.881 11.5699H12.8819ZM7.53662 13.803C7.54043 13.8354 7.54519 13.9145 7.55757 13.9916C7.69471 14.8192 7.83184 15.6477 7.97088 16.4752C8.06325 17.0247 8.15562 17.5732 8.25752 18.1208C8.32228 18.4703 8.65844 18.7274 8.98985 18.6979C9.36791 18.6636 9.64218 18.3684 9.64789 17.9875C9.6498 17.8523 9.61837 17.7227 9.59647 17.5904C9.37077 16.26 9.15174 14.9277 8.92604 13.5973C8.86319 13.2278 8.50893 12.9555 8.16515 12.9974C7.77946 13.044 7.52615 13.3488 7.53567 13.803H7.53662ZM18.2644 13.8183C18.2739 13.3469 18.0177 13.0441 17.6292 12.9983C17.2826 12.9574 16.9426 13.2174 16.875 13.605C16.6798 14.7134 16.4893 15.8229 16.3084 16.9333C16.2493 17.298 16.1484 17.658 16.156 18.0313C16.1617 18.3237 16.3217 18.5227 16.5788 18.636C16.8512 18.757 17.1026 18.6989 17.3273 18.5065C17.5178 18.3437 17.553 18.117 17.5911 17.8904C17.7282 17.0695 17.8654 16.2486 18.0016 15.4277C18.0939 14.8696 18.1844 14.3106 18.2654 13.8173L18.2644 13.8183ZM13.5905 15.8543C13.5905 15.1734 13.5905 14.4925 13.5905 13.8116C13.5905 13.3355 13.2943 12.9974 12.881 12.9964C12.461 12.9955 12.162 13.3259 12.161 13.7992C12.1601 15.1687 12.161 16.539 12.161 17.9085C12.161 18.3608 12.4601 18.6912 12.8696 18.6979C13.281 18.7055 13.5876 18.3646 13.5886 17.897C13.5895 17.2161 13.5886 16.5352 13.5886 15.8543H13.5905Z'
          fill='currentColor'
        />
        <path
          d='M5.7667 7.28318H19.9399C19.9428 7.17366 19.8647 7.11176 19.8095 7.04415C18.3639 5.23857 16.9163 3.43394 15.4688 1.63026C15.3898 1.53122 15.3069 1.43504 15.2327 1.33219C14.9765 0.984596 15.0203 0.566532 15.3365 0.317027C15.6602 0.0618078 16.0916 0.118946 16.3669 0.460826C17.1211 1.3998 17.8715 2.34069 18.6248 3.28062C19.66 4.5729 20.697 5.86423 21.7284 7.15842C21.8131 7.26413 21.9045 7.29365 22.0331 7.2927C22.7873 7.28889 23.5406 7.2908 24.2948 7.2927C24.7739 7.29365 25.0653 7.58315 25.0662 8.05836C25.0672 8.47833 25.0662 8.89829 25.0662 9.31731C25.0662 9.86679 24.7891 10.1411 24.2301 10.1411C18.6753 10.1411 13.1214 10.1411 7.56656 10.1411C5.5429 10.1411 3.52019 10.1411 1.49654 10.1401C0.972765 10.1401 0.689929 9.85632 0.688976 9.33826C0.688976 8.89449 0.687072 8.45166 0.689929 8.00788C0.692786 7.59172 0.993716 7.29175 1.40988 7.29079C2.17172 7.28889 2.93357 7.28699 3.69542 7.2927C3.84208 7.29365 3.94207 7.25746 4.03825 7.13652C5.81145 4.91859 7.58942 2.70161 9.36738 0.483681C9.65307 0.126565 10.0845 0.056094 10.414 0.311313C10.7501 0.571294 10.7797 1.01412 10.4835 1.38457C8.98265 3.25776 7.48181 5.13 5.98096 7.0032C5.91811 7.08224 5.85907 7.16319 5.76765 7.28413L5.7667 7.28318Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_38_1049'>
          <rect
            x='0.6875'
            y='0.159302'
            width='24.3782'
            height='21.3917'
            rx='10.6959'
            fill='currentColor'
          />
        </clipPath>
      </defs>
    </svg>
  );
}
