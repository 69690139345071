import { createContext, useCallback, useState } from 'react';
import { getCookie } from '../api/cookies';
import { WebsocketContextType, WebsocketProviderProps } from './types/WebSocketTypes';

export const WebsocketContext = createContext<WebsocketContextType | undefined>(undefined);

export const WebsocketProvider: React.FC<WebsocketProviderProps> = ({ children }) => {
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const token = getCookie('fruktonos-auth');

  const connect = useCallback((url: string) => {
    if (!token) {
      return null;
    }
    const newSocket = new WebSocket(url);
    setSocket(newSocket);

    newSocket.onopen = (event) => {
      console.log('WebSocket connection opened', event);
    };

    newSocket.onclose = () => {
      console.log('WebSocket connection closed');
      setSocket(null);
    };

    newSocket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    newSocket.onmessage = (event) => {
      console.log('WebSocket message received:', event.data);
    };
  }, []);

  const disconnect = useCallback(() => {
    if (socket) {
      socket.close();
    }
  }, [socket]);

  const sendMessage = useCallback(
    (message: string) => {
      if (socket && socket.readyState === WebSocket.OPEN) {
        socket.send(message);
      } else {
        console.error('WebSocket is not open. Unable to send message:', message);
      }
    },
    [socket]
  );

  return <WebsocketContext.Provider value={{ socket, connect, disconnect, sendMessage }}>{children}</WebsocketContext.Provider>;
};
